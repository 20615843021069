import { createReducer } from '@reduxjs/toolkit';

import initialState from './map.initialState';

import { ACTION_TYPES } from './map.constant';

const handleReset = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.error = [false, null];
};

const handleFetchEndPartial = (state, action) => {
  state.data = {
    results: action.payload,
  };
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
  state.data = initialState.data;
};

const handleUpdateLastSearch = (state, action) => {
  state.lastSearch = action.payload;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.END_PARTIAL]: handleFetchEndPartial,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.UPDATE.LAST_SEARCH]: handleUpdateLastSearch,
});
