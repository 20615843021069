import { combineReducers } from 'redux';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';
import toaster from '@spot/shared-store/toaster';

import login from '@spot-spotprospect/modules/login/store';
import dashboard from '@spot-spotprospect/modules/dashboard/store';

import configs from './configs';

const appReducer = combineReducers({
  [auth.name]: auth.reducer,
  [router.name]: router.reducer,
  [toaster.name]: toaster.reducer,
  [login.name]: login.reducer,
  [configs.name]: configs.reducer,
  [dashboard.name]: dashboard.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
