import reducer from './drawers.reducer';
import * as selector from './drawers.selector';
import * as constant from './drawers.constant';
import subscriber from './drawers.subscriber';
import saga from './drawers.saga';

export default {
  reducer,
  constant,
  selector,
  subscriber,
  saga,
  name: constant.NAME,
};
