import { useMemo } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import rootReducer from './root.reducer';
import rootSubscribe from './root.subscriber';
import rootSaga from './root.saga';

const initSaga = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, LogRocket.reduxMiddleware()];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export function useStore() {
  const currentStore = useMemo(() => initSaga(), []);

  currentStore.subscribe(rootSubscribe(currentStore));

  return currentStore;
}
