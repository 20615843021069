import { ACTION_TYPES } from './auth.constant';

export const setToken = (
  token,
  cookieKey: any = undefined,
  cookieDomain: any = undefined
) => ({
  type: ACTION_TYPES.TOKEN.SET,
  token,
  cookieKey,
  cookieDomain,
});

export const unsetToken = (
  cookieKey: any = undefined,
  cookieDomain: any = undefined
) => ({
  type: ACTION_TYPES.TOKEN.UNSET,
  cookieKey,
  cookieDomain,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
