import { all } from 'redux-saga/effects';

import filters from './filters';
import category from './category';
import region from './region';
import map from './map';

function* watch() {
  yield all([
    filters.saga.watch(),
    region.saga.watch(),
    map.saga.watch(),
    category.saga.watch(),
  ]);
}

export default {
  watch,
};
