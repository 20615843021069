import { isEqual } from 'lodash';
import { takeLatest, call, select } from 'redux-saga/effects';

import { ACTION_TYPES } from './misc.constant';

function* handleUpdateMode(action) {
  const { payload } = action;

  yield call(() => window.localStorage.setItem('mode', payload));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.UPDATE.MODE, handleUpdateMode);
}

export default {
  watch,
};
