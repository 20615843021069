import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DASHBOARD_ROUTES from './constants/dashboardRoutes.constant';

import useProtectedByAuth from '@spot-spotprospect/hooks/useProtectedByAuth.hook';

import DashboardScreen from './screens/Dashboard.screen';

const DashboardModule = () => {
  useProtectedByAuth();

  return (
    <>
      <Switch>
        <Route exact path={DASHBOARD_ROUTES.ROOT} component={DashboardScreen} />
      </Switch>
    </>
  );
};

export default DashboardModule;
