import React from 'react';
import { Box, Typography, TextField, Chip } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { flow, groupBy } from 'lodash';
import { Formik, Form } from 'formik';
import { debounce } from 'lodash';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { v4 } from 'uuid';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import useAuth from '@spot/shared-hooks/useAuth.hook';

import auth from '@spot/shared-store/auth';

import toDateYear from '@spot-spotprospect/utils/toDateYear.util';

import BoxWhiteComponent from '@spot-spotprospect/components/Box/BoxWhite.component';
import LogoComponent from '@spot-spotprospect/components/Logo.component';
import MapGeoJsonComponent from '@spot-spotprospect/components/MapGeoJson.component';
import FormArrFormikComponent from '@spot-spotprospect/components/FormArrFormik.component';

import useEffectOnce from '@spot-spotprospect/hooks/useEffectOnce.hook';

import MapLayout from '../layouts/Dashboard.layout';

import category from '../store/category';
import region from '../store/region';
import filters from '../store/filters';
import map from '../store/map';

const DashboardScreen = () => {
  const [key, setKey] = React.useState(v4());
  const [openProfile, setOpenProfile] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);
  const [center, setCenter] = React.useState<any>(null);

  const { signedIn } = useAuth();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataUser: useSelector(auth.selector.selectUser),
    dataCategory: useSelector(category.selector.selectData),
    dataRegion: useSelector(region.selector.selectData),
    dataMap: useSelector(map.selector.selectData),
    filtersMap: useSelector(filters.selector.selectState),
    loadingRegion: useSelector(region.selector.selectLoading),
    loadingMap: useSelector(map.selector.selectLoading),
  };

  const dispatchRedux = {
    serviceGet: flow(category.action.serviceGet, dispatch),
    serviceGetMap: flow(map.action.serviceGet, dispatch),
    serviceGetRegion: flow(region.action.serviceGet, dispatch),
    updateFilters: flow(filters.action.updateFilters, dispatch),
    resetState: flow(map.action.resetState, dispatch),
    resetStateFilters: flow(filters.action.resetState, dispatch),
    resetStateRegion: flow(region.action.resetState, dispatch),
    unsetToken: flow(auth.action.unsetToken, dispatch),
  };

  useEffectOnce(() => {
    if (signedIn) {
      dispatchRedux.serviceGet();
      dispatchRedux.serviceGetRegion('');
    }
  });

  const ufsRet = Object.entries(groupBy(selectorRedux?.dataRegion, 'uf'));

  const ufsFormatted = Object.values(ufsRet).reduce((acc, curr) => {
    return {
      ...acc,
      [curr[0]]: curr[1],
    };
  }, {});

  const footer = (
    <Box
      px="20px"
      width="100%"
      position="absolute"
      bottom={'20px'}
      zIndex={997}
      display="flex"
    >
      <BoxWhiteComponent>
        <Box>
          <Box>
            <Typography
              color="gray.400"
              variant="subtitle2"
              textAlign="center"
              pb="5px"
            >
              <strong>SpotProspect</strong>
            </Typography>
            <Typography
              color="gray.400"
              variant="subtitle2"
              textAlign="center"
              pb="5px"
            >
              SpotSat LTDA© {toDateYear()}
            </Typography>
            <Typography color="gray.400" variant="subtitle2" textAlign="center">
              Todos os direitos reservados
            </Typography>
          </Box>
        </Box>
      </BoxWhiteComponent>
    </Box>
  );

  const sidebar = (
    <Box
      display="flex"
      flexDirection="column"
      px="32px"
      py="24px"
      gap="24px"
      position="sticky"
      top="16px"
      width="280px"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={0.5}>
        <Avatar src="" sx={{ width: 40, height: 40 }} />
        <Box alignSelf="center" height="27px">
          <img src={process.env.PUBLIC_URL + '/img/brasil.png'} alt="brasil" />
        </Box>
        <Typography variant="subtitle2" color="info.500" fontWeight={900}>
          {selectorRedux.dataUser?.user.full_name}
        </Typography>
        <Typography variant="bodyxs" color="gray.400" fontWeight={500}>
          {selectorRedux.dataUser?.user.email}
        </Typography>
      </Box>
      <Box mx={'-16px'}>
        <nav>
          <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => dispatchRedux.unsetToken()}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  color="gray.400"
                >
                  <LogoutOutlinedIcon />
                  <Typography
                    variant="subtitle1"
                    color="gray.400"
                    fontWeight={500}
                  >
                    Sair
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </Box>
  );
  return (
    <MapLayout>
      <Box display="grid" gridTemplateColumns="1fr" alignItems="start" gap={4}>
        <Box width="100%" height="100vh" key={key}>
          <MapGeoJsonComponent
            center={center}
            geojson={selectorRedux?.dataMap?.results}
            loading={selectorRedux.loadingMap}
          />
        </Box>
      </Box>

      <Box
        position="absolute"
        top={'202px'}
        left={'12px'}
        zIndex={999}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Button
          variant="contained"
          color="inherit"
          sx={{
            p: 0,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 0.5,
            backgroundColor: 'white',
          }}
          onClick={() => setOpenProfile(true)}
        >
          <PersonOutlineOutlinedIcon />
        </Button>
        <Button
          variant="contained"
          color="inherit"
          loading={selectorRedux.loadingMap}
          sx={{
            p: 0,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 0.5,
            backgroundColor: 'white',
            '.MuiLoadingButton-loadingIndicator': {
              color: 'white',
            },
          }}
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </Button>
      </Box>

      <Drawer open={open || false} anchor="left" onClose={() => setOpen(false)}>
        <Box display="flex" flexDirection="column" gap={4} p={2} width={500}>
          <Formik
            initialValues={{
              category: selectorRedux.filtersMap?.category || [],
              region: selectorRedux.filtersMap?.region || '',
              uf: selectorRedux.filtersMap?.uf || '',
            }}
            onSubmit={async (values: any) => {
              const currentRegion = selectorRedux?.dataRegion?.find(
                (dr) => dr.id === values?.region
              );

              dispatchRedux.updateFilters({
                category: values?.category,
                uf: values?.uf,
                region: currentRegion?.id,
                lat: currentRegion?.lat,
                lng: currentRegion?.lng,
              });

              if (currentRegion.lat) {
                setCenter([currentRegion.lng, currentRegion.lat]);
              }

              setOpen(false);

              return true;
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FormArrFormikComponent
                  boxProps={{
                    gap: 3,
                  }}
                  fields={[
                    [
                      {
                        label: 'UF',
                        name: 'uf',
                        variant: 'select',
                        selectFieldProps: {
                          value: values?.uf,
                        },
                        options:
                          ufsRet?.map(([value]) => ({
                            label: value,
                            value: value,
                          })) || [],
                      },
                    ],
                    [
                      {
                        label: 'Cidade',
                        name: 'region',
                        variant: 'select',
                        selectFieldProps: {
                          value: values?.region,
                        },
                        options:
                          ufsFormatted[values?.uf]?.map((dr) => ({
                            label: dr?.description,
                            value: dr?.id,
                          })) || [],
                      },
                    ],
                    [
                      {
                        label: 'Categoria',
                        name: 'category',
                        variant: 'select',
                        selectFieldProps: {
                          multiple: true,
                          value: values?.category,
                          renderValue: (selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    selectorRedux?.dataCategory?.find(
                                      (v) => value === v.id
                                    )?.name
                                  }
                                />
                              ))}
                            </Box>
                          ),
                        },
                        options: [...(selectorRedux?.dataCategory || [])].map(
                          (c) => ({
                            label: c.name,
                            value: c.id,
                          })
                        ),
                      },
                    ],
                  ]}
                />

                <Box display="flex" gap={2} justifyContent="flex-end" mt={3}>
                  <Button
                    size="large"
                    onClick={() => {
                      dispatchRedux.resetState();
                      dispatchRedux.resetStateFilters();
                      setKey(v4());

                      setOpen(false);
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    loading={selectorRedux.loadingMap}
                    disabled={selectorRedux.loadingMap}
                    size="large"
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                  >
                    Filtrar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer>

      <Drawer
        open={openProfile}
        anchor="left"
        onClose={() => setOpenProfile(false)}
      >
        {sidebar}
        {footer}
      </Drawer>
    </MapLayout>
  );
};

export default DashboardScreen;
