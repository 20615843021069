import { combineReducers } from 'redux';

import filters from './filters';
import category from './category';
import region from './region';
import map from './map';

const reducer = combineReducers({
  [filters.name]: filters.reducer,
  [category.name]: category.reducer,
  [map.name]: map.reducer,
  [region.name]: region.reducer,
});

export default reducer;
