const ROUTES = {
  ROOT: '/dashboard',
};

const DASHBOARD_ROUTES = {
  ROOT: ROUTES.ROOT,
};

export const NAMESPACE = ROUTES.ROOT;

export default DASHBOARD_ROUTES;
