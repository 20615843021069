const initialState = {
  offset: '0',
  limit: '1000',
  category: '',
  region: '',
  uf: '',
  lat: '',
  lng: '',
  northeast_lat: '',
  northeast_lng: '',
  southweast_lat: '',
  southweast_lng: '',
};

export default initialState;
