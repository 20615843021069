export const NAME = 'filters';

const CONTEXT = 'DASHBOARD::FILTERS';

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const RESET = {
  STATE: RESET_STATE,
};

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
};

export const ACTION_TYPES = {
  RESET,
  UPDATE,
};
