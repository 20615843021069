import * as authCookie from './authCookie.util';

import { API_URL_WITH_MS } from '@spot/shared-configs/api.config';

const normalizeAPIResponse = async (res: any) => {
  const body = await res.json();

  return [res.ok, body];
};

const fetch = (url: string, config: any = {}) => {
  const { ms, auth, defaultHeaders = true, ...restConfig } = config;

  try {
    return window
      .fetch(
        `${API_URL_WITH_MS(ms)}/${url}`,
        Object.assign({}, restConfig, {
          headers: new Headers(
            Object.assign(
              defaultHeaders && {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              auth &&
                authCookie.hasAuthCookie() && {
                  Authorization: authCookie.getAuthCookie(),
                },
              restConfig.headers
            )
          ),
        })
      )
      .then(normalizeAPIResponse);
  } catch (e) {
    return [false, null];
  }
};

export default fetch;
