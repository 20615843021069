import router from '.';

let nextState = {};
let prevState = {};

const foo = (dispatch) => {
  //console.log
};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  foo(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
