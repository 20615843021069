import reducer from './authenticate.reducer';
import saga from './authenticate.saga';
import * as action from './authenticate.action';
import * as selector from './authenticate.selector';
import * as constant from './authenticate.constant';
// import subscriber from './authenticate.subscriber';
import initialState from './authenticate.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  // subscriber,
  name: constant.NAME,
};
