import { takeLatest, call, put } from 'redux-saga/effects';

import {
  deleteAuthCookie,
  setAuthCookie,
} from '@spot/shared-utils/authCookie.util';

import * as actions from './auth.action';
import { ACTION_TYPES } from './auth.constant';

function* handleUnsetToken(action) {
  yield call(deleteAuthCookie, action.cookieKey, action.cookieDomain);
}

function* handleSetToken(action) {
  yield call(
    setAuthCookie,
    action.token,
    action.cookieKey,
    action.cookieDomain
  );
}

export function* handleRefreshToken(service) {
  const [success, result] = yield call(service);

  if (success) {
    yield put(actions.setToken(result.token));
  }

  return [success, result];
}

function* watch() {
  yield takeLatest(ACTION_TYPES.TOKEN.UNSET, handleUnsetToken);
  yield takeLatest(ACTION_TYPES.TOKEN.SET, handleSetToken);
}

export default {
  watch,
};
