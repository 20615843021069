import { ACTION_TYPES } from './dialogChangePassword.constant';

export const servicePost = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchSuccess = (payload) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
});

export const fetchError = () => ({
  type: ACTION_TYPES.FETCH.ERROR,
});

export const open = (payload) => ({
  type: ACTION_TYPES.SET.OPEN,
  payload,
});

export const close = () => ({
  type: ACTION_TYPES.SET.CLOSE,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const updateVariant = (variant: 'initial' | 'finish') => ({
  type: ACTION_TYPES.UPDATE.VARIANT,
  payload: variant,
});
