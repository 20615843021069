import React from 'react';
import { Box } from '@mui/material';

const BoxWhiteComponent = ({ children }) => {
  return (
    <Box
      borderRadius={1}
      p={3}
      bgcolor="background.default"
      boxShadow="0px 0px 10px #0000000f"
    >
      {children}
    </Box>
  );
};

export default BoxWhiteComponent;
