const PATH = 'api';

export const MS: any = {
  DASHBOARD: process.env.REACT_APP_DASHBOARD_MS,
  DASHBOARD_ESG: process.env.REACT_APP_DASHBOARD_ESG_MS,
  OPERATION: process.env.REACT_APP_OPERATION_MS,
  USER: process.env.REACT_APP_USER_MS,
  POLYGONS: process.env.REACT_APP_POLYGONS_MS,
  REPORT: process.env.REACT_APP_REPORT_MS,
  BILLING: process.env.REACT_APP_BILLING_MS,
  ACCOUNT_SPOTPROSPECT: process.env.REACT_APP_ACCOUNT_SPOTPROSPECT_MS,
  MAP_SPOTPROSPECT: process.env.REACT_APP_MAP_SPOTPROSPECT_MS,
  ACCOUNT: process.env.REACT_APP_ACCOUNT_MS,
  TEMPORAL: process.env.REACT_APP_TEMPORAL_MS,
  ANALYSIS_ESG: process.env.REACT_APP_ANALYSIS_ESG_MS,
};

export const API_URL_WITH_MS = (ms: any) => `${MS[ms]}/${PATH}`;
