import { ACTION_TYPES } from './filters.constant';

export const updateFilters = (payload) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});
