import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset?: string;
  limit?: string;
  category?: any;
  region?: string;
  northeast_lat: string;
  northeast_lng: string;
  southweast_lat: string;
  southweast_lng: string;
};

export default async function getApiMapService(filters: Filters) {
  const filtersStringfy = qs.stringify(
    {
      ...filters,
      category: filters?.category?.filter((v) => v),
    },
    { arrayFormat: 'repeat', skipNulls: true }
  );

  const url = ['map', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'MAP_SPOTPROSPECT',
    auth: true,
  });
}
