import { isEqual } from 'lodash';
import { takeLatest, put, select } from 'redux-saga/effects';

// import { ACTION_TYPES as PAYMENT_ACTION_TYPES } from '../../payment.constant';

import dialogData from '.';

function* handleReset() {
  // const state = yield select(dialogData.selector.selectDialogAppState);
  // if (isEqual(dialogData.initialState, state)) {
  //   return true;
  // }
  // yield put(dialogData.action.reset());
}

function* watch() {
  // yield takeLatest(PAYMENT_ACTION_TYPES.CHANGE, handleReset);
}

export default {
  watch,
};
