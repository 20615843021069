import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';
import { TextField } from '@mui/material';

const FieldZipcodeComponent = ({
  name,
  onBlur = (value) => {},
  ...restProps
}) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
    meta: { error, touched },
  }: any) => {
    return (
      <>
        <NumberFormat
          customInput={TextField}
          format={'#####-###'}
          fullWidth
          label="CEP"
          variant="outlined"
          type="tel"
          value={field.value}
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);

            onBlur(value);
          }}
          onValueChange={(values: any) =>
            setFieldValue(field.name, values.value)
          }
          {...restProps}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldZipcodeComponent;
