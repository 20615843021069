const PALLET_SECONDARY = {
  main: '#053A65',
  light3: '#ECF0F3',
  light2: '#9BB0C1',
  light: '#376184',
  dark: '#284761',
  dark2: '#252F43',
  100: '#ECF0F3',
  200: '#9BB0C1',
  300: '#376184',
  400: '#284761',
  500: '#252F43',
};

const PALETTE_PRIMARY = {
  main: '#66B821',
  light: '#A3D47A',
  light2: '#D7EDC5',
  light3: '#F1F9EC',
  dark: '#71B239',
  dark2: '#437718',
  100: '#F1F9EC',
  200: '#D7EDC5',
  300: '#A3D47A',
  400: '#71B239',
  500: '#437718',
};

const PALLET_GRAY = {
  main: '#9CA1AA',
  light: '#F4F5F6',
  dark: '#313C4E',
  black: '#313C4E',
  100: '#F4F5F6',
  200: '#D6D8DC',
  300: '#9CA1AA',
  400: '#5A6371',
  500: '#313C4E',
};

const PALLET_WARNING = {
  main: '#FFAB00',
  light: '#FFD070',
  light2: '#FFE7B8',
  light3: '#FFF7E6',
  dark: '#BF840B',
  dark2: '#664400',
  100: '#FFF7E5',
  200: '#FFE7B8',
  300: '#FFD070',
  400: '#BF840B',
  500: '#664400',
};

const PALLET_SUCCESS = {
  main: '#CCE70B',
  light: '#D6EC3C',
  light2: '#EBF59D',
  light3: '#F5FACE',
  dark: '#98AD0B',
  dark2: '#616E07',
  100: '#F5FACE',
  200: '#EBF59D',
  300: '#D6EC3C',
  400: '#98AD0B',
  500: '#616E07',
};

const PALLET_ERROR = {
  main: '#F44336',
  light: '#F99790',
  light2: '#FCC4C0',
  light3: '#FEE0DE',
  dark: '#CB180B',
  dark2: '#790E07',
  100: '#FEE0DE',
  200: '#FCC4C0',
  300: '#F99790',
  400: '#CB180B',
  500: '#790E07',
};

const PALLET_INFO = {
  main: '#0362F0',
  light: '#3385FF',
  light2: '#B8D4FF',
  light3: '#E6F0FF',
  dark: '#003E99',
  dark2: '#002152',
  100: '#E6F0FF',
  200: '#B8D4FF',
  300: '#3385FF',
  400: '#003E99',
  500: '#002152',
};

const PALETTE = {
  primary: PALETTE_PRIMARY,
  secondary: PALLET_SECONDARY,
  gray: PALLET_GRAY,
  warning: PALLET_WARNING,
  success: PALLET_SUCCESS,
  error: PALLET_ERROR,
  info: PALLET_INFO,
};

const PALETTE_DARK = {
  primary: PALETTE_PRIMARY,
};

const TYPOGRAPHY = {
  fontFamily: '"Raleway", sans-serif',
  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
  },
  bodyxs: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
  },
  subtitle1500: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
  },
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '62px',
    lineHeight: '73px',
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '47px',
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
  },
};

const theme = (mode) => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1166,
        xl: 1536,
      },
    },
    palette: mode === 'light' ? PALETTE : { mode: 'dark', ...PALETTE_DARK },
    typography: TYPOGRAPHY,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {},
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            legend: {},
          },
        },
      },
    },
  };
};

export default theme;
