import LOGIN_ROUTES from '@spot-spotprospect/modules/login/constants/loginRoutes.constant';
import DASHBOARD_ROUTES from '@spot-spotprospect/modules/dashboard/constants/dashboardRoutes.constant';

const NAMESPACE = '/';
const NAMESPACE_LOGIN = LOGIN_ROUTES.ROOT;
const NAMESPACE_DASHBOARD = DASHBOARD_ROUTES.ROOT;

export const NAMESPACES = {
  ROOT: NAMESPACE,
  LOGIN: NAMESPACE_LOGIN,
  DASHBOARD: NAMESPACE_DASHBOARD,
};

const ROUTES = {
  ROOT: NAMESPACE,
  LOGIN: LOGIN_ROUTES,
  DASHBOARD: DASHBOARD_ROUTES,
};

export default ROUTES;
