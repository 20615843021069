import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LOGIN_ROUTES from './constants/loginRoutes.constant';

import AuthScreen from './screens/Auth.screen';

const LoginModule = () => {
  return (
    <>
      <Switch>
        <Route exact path={LOGIN_ROUTES.AUTH} component={AuthScreen} />
      </Switch>
    </>
  );
};

export default LoginModule;
