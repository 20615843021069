import { ACTION_TYPES } from './toaster.constant';

export const show = (payload: {
  message?: string;
  variant: 'success' | 'error' | 'info' | 'warning' | 'default';
  configs?: {
    [key: string]: any;
  };
}) => ({
  type: ACTION_TYPES.SHOW,
  payload,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
