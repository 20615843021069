import { useSelector } from 'react-redux';
import auth from '@spot/shared-store/auth';

type objTypes = {
  signedIn: boolean;
  token: string;
  user: any;
};

export default function useAuthHook(): objTypes {
  const selectorAuthRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
    token: useSelector(auth.selector.selectToken),
    user: useSelector(auth.selector.selectUser),
  };

  const props: objTypes = {
    signedIn: selectorAuthRedux.signedIn,
    token: selectorAuthRedux.token,
    user: selectorAuthRedux.user,
  };

  return props;
}
