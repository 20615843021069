import { isEqual } from 'lodash';
import { takeLatest, put, select, delay } from 'redux-saga/effects';

import currentStore from '.';

function* handleReset() {
  const state = yield select(currentStore.selector.selectState);

  if (isEqual(currentStore.initialState, state)) {
    return true;
  }

  yield delay(500);

  yield put(currentStore.action.reset());
}

function* handleServicePost(action) {
  const { payload } = action;

  yield put(currentStore.action.fetchStart());

  yield delay(2000);

  yield put(currentStore.action.fetchSuccess(payload));

  yield put(currentStore.action.updateVariant('finish'));
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );

  yield takeLatest(currentStore.constant.ACTION_TYPES.SET.CLOSE, handleReset);
}

export default {
  watch,
};
