import flow from 'lodash/flow';
import { takeLatest, takeEvery, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import get from 'lodash/get';

import toaster from '@spot/shared-store/toaster';

import getApiMapService from '@spot/shared-services/map_spotprospect/getApiMap.service';

import filters from '../filters';

import currentState from '.';

const handleServiceGetPartial = function* (offset) {
  const handlers = {
    fetchEndPartial: flow(currentState.action.fetchEndPartial, put),
  };

  const seletors = {
    filters: yield select(filters.selector.selectState),
    results: yield select(currentState.selector.selectData),
  };

  const filtersObj = pickBy(omit(seletors.filters, 'lat', 'lng'));

  const [success, result] = yield call(
    getApiMapService as any,
    omit({ ...filtersObj, offset }, 'uf')
  );

  let formattedRegions = [...(seletors.results?.results || [])];

  result?.results?.map((r: any) => {
    const indexFr = formattedRegions.findIndex((fr) => fr.id === r.id);
    const hasFormattedRegion = indexFr !== -1;

    if (!hasFormattedRegion) {
      formattedRegions = [...formattedRegions, r];
    }

    if (hasFormattedRegion) {
      formattedRegions[indexFr] = {
        ...formattedRegions[indexFr],
        places: [
          ...get(formattedRegions, `[${indexFr}].places`, []),
          ...get(r, `places`, []),
        ],
      };
    }
  });

  yield handlers.fetchEndPartial(formattedRegions);

  return [success, result];
};

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(filters.selector.selectState),
    lastSearch: yield select(currentState.selector.selectLastSearch),
    loading: yield select(currentState.selector.selectLoading),
  };

  const handlers = {
    updateLastSearch: flow(currentState.action.updateLastSearch, put),
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    resetState: flow(currentState.action.resetState, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  if (!seletors.filters?.region) {
    return null;
  }

  try {
    yield handlers.resetState();

    yield handlers.fetchStart();

    const [successFirst, resultFirst] = yield call(handleServiceGetPartial, 0);

    if (!successFirst) {
      throw resultFirst;
    }

    const count = resultFirst?.metadata?.count;
    const limit = resultFirst?.metadata?.limit;

    const requestsToCall = Math.ceil(count / limit);

    const requestsArr = [...Array(requestsToCall)].map((_, index) => {
      return call(
        index > 0 ? handleServiceGetPartial : () => [true, resultFirst],
        index * limit
      );
    });

    let requestsPartion: any = [];

    for (const c of requestsArr) {
      const result = yield c;

      requestsPartion = [...requestsPartion, result];
    }

    const result = requestsPartion.reduce(
      (acc, [, result]) => [...acc, ...result.results],
      []
    );

    yield handlers.updateLastSearch(seletors.filters);

    if (result.length === 0) {
      yield handlers.show({
        message: 'Nenhum dado encontrado para esta região.',
        variant: 'error',
      });
    }

    return yield handlers.fetchEnd({
      results: result,
    });
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeEvery(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
