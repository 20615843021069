import { all } from 'redux-saga/effects';

import drawers from './drawers';
import misc from './misc';

function* watch() {
  yield all([drawers.saga.watch(), misc.saga.watch()]);
}

export default {
  watch,
};
