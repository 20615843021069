import reducer from './drawerNotification.reducer';
import * as action from './drawerNotification.action';
import * as selector from './drawerNotification.selector';
import * as constant from './drawerNotification.constant';
import initialState from './drawerNotification.initialState';
import subscriber from './drawerNotification.subscriber';
import saga from './drawerNotification.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
