import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';

import toaster from '@spot/shared-store/toaster';

import getApiDashboardSummaryService from '@spot/shared-services/dashboard/getApiDashboardsSummary.service';

import { ACTION_TYPES } from '../dashboard.constant';

import filters from '../filters';

import currentState from '.';
import omit from 'lodash/omit';

const handleReset = function* (action) {
  yield put(currentState.action.resetState());
};

function* watch() {
  //
}

export default {
  watch,
};
