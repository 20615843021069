import Cookies from 'js-cookie';
import decode from 'jwt-decode';

export function hasAuthCookie(
  cookieKey = process.env.REACT_APP_COOKIE_KEY_TOKEN
) {
  return !!Cookies.get(cookieKey);
}

export function getAuthCookie(
  cookieKey = process.env.REACT_APP_COOKIE_KEY_TOKEN
) {
  return Cookies.get(cookieKey);
}

export function getAuthCookieObj(
  cookieKey = process.env.REACT_APP_COOKIE_KEY_TOKEN
) {
  return hasAuthCookie(cookieKey) && decode(getAuthCookie(cookieKey));
}

export function setAuthCookie(
  token,
  cookieKey = process.env.REACT_APP_COOKIE_KEY_TOKEN,
  cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN
) {
  const tokenDecoded: any = decode(token);

  return Cookies.set(cookieKey, token, {
    domain: cookieDomain,
    // expires: new Date(tokenDecoded.exp * 1000),
  });
}

export function deleteAuthCookie(
  cookieKey = process.env.REACT_APP_COOKIE_KEY_TOKEN,
  cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN
) {
  return Cookies.remove(cookieKey, {
    domain: cookieDomain,
  });
}
