import reducer from './filters.reducer';
import * as selector from './filters.selector';
import * as constant from './filters.constant';
import * as action from './filters.action';
import saga from './filters.saga';

export default {
  reducer,
  constant,
  selector,
  action,
  saga,
  name: constant.NAME,
};
