import decode from 'jwt-decode';

import {
  hasAuthCookie,
  getAuthCookie,
} from '@spot/shared-utils/authCookie.util';

const initialState = {
  signedIn: hasAuthCookie(),
  token: hasAuthCookie() ? getAuthCookie() : null,
  user: hasAuthCookie() ? decode(getAuthCookie()) : null,
};

export default initialState;
