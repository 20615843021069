import React from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import ThemeProvider from '@spot-spotprospect/providers/Theme.provider';

const MapLayout = ({ children }) => {
  return (
    <ThemeProvider>
      <Box position="relative">{children}</Box>
    </ThemeProvider>
  );
};

export default MapLayout;
