export const NAME = 'map';

const CONTEXT = 'DASHBOARD::MAP';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_END_PARTIAL = `${CONTEXT}::FETCH::END_PARTIAL`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const UPDATE_LAST_SEARCH = `${CONTEXT}::UPDATE::LAST_SEARCH`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE = {
  LAST_SEARCH: UPDATE_LAST_SEARCH,
};

const SERVICE = {
  GET: SERVICE_GET,
};

const RESET = {
  STATE: RESET_STATE,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  END_PARTIAL: FETCH_END_PARTIAL,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  SERVICE,
  UPDATE,
};
