import React, { Fragment } from 'react';
import {
  Box,
  TextField,
  BoxProps,
  TextFieldProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getIn, Field, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import Autocomplete from '@mui/material/Autocomplete';

import FieldZipcodeComponent from '@spot/shared-components/Fields/FieldZipcode.component';
import FieldDocumentComponent from '@spot/shared-components/Fields/FieldDocument.component';

const FormArrFormikComponent = ({
  fields,
  boxProps,
}: {
  fields: Array<
    Array<{
      label: string;
      name: string;
      variant?:
        | 'textfield'
        | 'checkbox'
        | 'password'
        | 'cpf'
        | 'cnpj'
        | 'date'
        | 'custom'
        | 'autocomplete'
        | 'select'
        | 'zipcode';
      textFieldProps?: TextFieldProps;
      selectFieldProps?: any;
      dateProps?: any;
      options?: Array<{ label: string; value: string }>;
      formattedValue?: any;
      render?: any;
      autocompleteProps?: any;
    }>
  >;
  boxProps?: BoxProps;
}) => {
  const formik = useFormikContext();

  const inputDefault = (field) => {
    return (
      <Field name={field.name}>
        {({
          form: { errors, touched },
          field: { onBlur: fieldOnBlur, ...fieldProp },
        }) => {
          const fieldError = getIn(errors, field.name);
          const showError = getIn(touched, field.name) && !!fieldError;

          return (
            <TextField
              error={showError}
              helperText={
                showError ? fieldError : field?.textFieldProps?.helperText
              }
              label={field.label}
              onBlur={function (e) {
                fieldOnBlur(e ?? field.name);
              }}
              {...fieldProp}
              {...field.textFieldProps}
              {...(field.formattedValue && {
                value: field.formattedValue(fieldProp.value),
              })}
            >
              {field.options?.map((option, index) => (
                <option key={`${option.value}-${index}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          );
        }}
      </Field>
    );
  };

  const inputAutocomplete = (field) => {
    return (
      <Autocomplete
        disablePortal
        renderInput={(params) =>
          inputDefault({
            ...field,
            textFieldProps: {
              ...field?.textFieldProps,
              ...params,
            },
          })
        }
        onBlur={(ev: any) => {
          const currentValue = ev.target.value;

          const options = field?.autocompleteProps?.options || [];

          const currentOption = options?.find((o) => o?.label === currentValue);

          formik.setFieldValue(field.name, currentOption);
        }}
        {...field?.autocompleteProps}
      />
    );
  };

  const inputCheckbox = (field) => (
    <Field name={field.name}>
      {({ form: { errors, touched }, field: { value, ...fieldProp } }) => {
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(ev, checked) => {
                    formik.setFieldValue(field.name, checked);
                  }}
                />
              }
              label={field.label}
            />
          </FormGroup>
        );
      }}
    </Field>
  );

  const inputPassword = (field) => {
    return (
      <Field name={field.name}>
        {({
          form: { errors, touched },
          field: { onBlur: fieldOnBlur, ...fieldProp },
        }) => {
          const fieldError = getIn(errors, field.name);
          const showError = getIn(touched, field.name) && !!fieldError;

          return (
            <TextField
              error={showError}
              helperText={
                showError ? fieldError : field?.textFieldProps?.helperText
              }
              type="password"
              label={field.label}
              onBlur={function (e) {
                fieldOnBlur(e ?? field.name);
              }}
              {...fieldProp}
              {...field.textFieldProps}
              {...(field.formattedValue && {
                value: field.formattedValue(fieldProp.value),
              })}
            >
              {field.options?.map((option, index) => (
                <option key={`${option.value}-${index}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          );
        }}
      </Field>
    );
  };

  const inputDate = (field) => {
    return (
      <Field name={field.name}>
        {({
          form: { errors, touched },
          field: { onBlur: fieldOnBlur, ...fieldProp },
        }) => {
          const fieldError = getIn(errors, field.name);
          const showError = getIn(touched, field.name) && !!fieldError;

          return (
            <Box
              position="relative"
              sx={{
                '.react-datepicker-popper': {
                  zIndex: 99,
                  inset: 'unset !important',
                  top: '0px !important',
                  right: '8px !important',
                },
              }}
            >
              <DatePicker
                locale={ptBR}
                dateFormat="dd/MM/yyyy"
                selected={fieldProp?.value}
                onChange={(date) => {
                  formik.setFieldValue(field.name, date);
                }}
                customInput={
                  <TextField
                    label={field.label}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    {...fieldProp}
                    {...field.textFieldProps}
                  />
                }
                {...field?.dateProps}
              />
            </Box>
          );
        }}
      </Field>
    );
  };

  const inputCpf = (field) => {
    return (
      <FieldDocumentComponent
        name={field.name}
        label={field.label}
        {...field.textFieldProps}
        format={'###.###.###-##'}
      />
    );
  };

  const inputCnpj = (field) => {
    return (
      <FieldDocumentComponent
        name={field.name}
        label={field.label}
        {...field.textFieldProps}
        format={'##.###.###/####-##'}
      />
    );
  };

  const inputZipcode = (field) => {
    return (
      <FieldZipcodeComponent
        name={field.name}
        label={field.label}
        {...field.textFieldProps}
      />
    );
  };

  const inputSelect = (field) => {
    return (
      <Field name={field.name}>
        {({ form: { errors, touched }, field: { value, ...fieldProp } }) => {
          return (
            <FormControl fullWidth>
              <InputLabel>{field.label}</InputLabel>
              <Select
                label={field.label}
                {...fieldProp}
                {...field.selectFieldProps}
              >
                {field.options?.map((option, index) => (
                  <MenuItem
                    key={`${option.value}-${index}`}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      </Field>
    );
  };

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {fields.map((f: any, index) => {
        return (
          <Box
            key={`field-${index}`}
            display="grid"
            gridTemplateColumns={`repeat(${f.length}, 1fr)`}
            gap={boxProps?.gap}
            alignItems="center"
          >
            {f.map((field) => {
              const variant = field?.variant || 'textfield';

              return (
                <Fragment key={field.name}>
                  {variant === 'password' && inputPassword(field)}
                  {variant === 'textfield' && inputDefault(field)}
                  {variant === 'checkbox' && inputCheckbox(field)}
                  {variant === 'cnpj' && inputCnpj(field)}
                  {variant === 'cpf' && inputCpf(field)}
                  {variant === 'zipcode' && inputZipcode(field)}
                  {variant === 'date' && inputDate(field)}
                  {variant === 'custom' && field?.render(field)}
                  {variant === 'autocomplete' && inputAutocomplete(field)}
                  {variant === 'select' && inputSelect(field)}
                </Fragment>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default FormArrFormikComponent;
