import { all } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';

import login from '@spot-spotprospect/modules/login/store';
import dashboard from '@spot-spotprospect/modules/dashboard/store';

import configs from './configs';

function* rootSaga() {
  yield all([
    login.saga.watch(),
    auth.saga.watch(),
    router.saga.watch(),
    configs.saga.watch(),
    dashboard.saga.watch(),
  ]);
}

export default rootSaga;
