import reducer from './dialogChangePassword.reducer';
import * as action from './dialogChangePassword.action';
import * as selector from './dialogChangePassword.selector';
import * as constant from './dialogChangePassword.constant';
import initialState from './dialogChangePassword.initialState';
import saga from './dialogChangePassword.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
