import foo from '.';

let nextState = {};
let prevState = {};

const changeName = (dispatch) => {};

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  changeName(dispatch);

  prevState = nextState;
};

export default {
  subscribe,
};
