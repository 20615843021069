import { createReducer } from '@reduxjs/toolkit';

import initialState from './misc.initialState';
import { ACTION_TYPES } from './misc.constant';

const handleUpdateMode = (state, action) => {
  state.mode = action.payload;
};

const handleReset = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.UPDATE.MODE]: handleUpdateMode,
  [ACTION_TYPES.RESET]: handleReset,
});
