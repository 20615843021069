import React, { Fragment } from 'react';
import L from 'leaflet';
import { Box, Button } from '@mui/material';
import { uniqBy, countBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { debounce } from 'lodash';
import { v4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';

import useEffectOnce from '@spot-spotprospect/hooks/useEffectOnce.hook';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.featuregroup.subgroup';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import filters from '@spot-spotprospect/modules/dashboard/store/filters';
import mapStore from '@spot-spotprospect/modules/dashboard/store/map';

export default function MapGeoJsonComponent(props: any) {
  const dispatch = useDispatch();

  const [pointsToGoogle, setPointsToGoogle] = React.useState([]);
  const [showButton, setShowButton] = React.useState(false);

  const control: any = React.useRef(null);
  const map: any = React.useRef(null);

  const [full, setFull] = React.useState<any>(false);

  const dispatchRedux = {
    updateFilters: flow(filters.action.updateFilters, dispatch),
    serviceGet: flow(mapStore.action.serviceGet, dispatch),
  };

  const propsFull: any = {
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  };

  const handleGetLatLngFromMarkers = (arr) => arr.map((a: any) => a._latlng);

  const handleCleanLayers = () => {
    map.current.eachLayer(function (layer) {
      if (
        layer?._url !==
          'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}' &&
        layer?._url !==
          'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png'
      ) {
        map.current.removeLayer(layer);
      }
    });
  };

  const Wrapper = full ? Box : React.Fragment;

  const handleToMap = () => {
    if (props.geojson) {
      if (control.current) {
        control.current.remove();
      }
      handleCleanLayers();

      setPointsToGoogle([]);
      setShowButton(false);

      if (props.geojson.length === 0) {
        return undefined;
      }

      control.current = L.control.layers(undefined, undefined, {
        collapsed: false,
      });

      const markers = (L as any).markerClusterGroup({
        chunkedLoading: true,
      });

      const geojsonOptions = {
        style: function (feature) {
          if (feature?.properties?.name == 'CAR') {
            return {
              fillColor: feature?.properties?.color,
              weight: 2,
              opacity: 1,
              color: feature?.properties?.color,
              dashArray: '3',
              fillOpacity: 0.05,
            };
          } else if (feature?.properties?.name == 'Glebas de Operações') {
            return {
              fillColor: feature?.properties?.color,
              weight: 3,
              opacity: 1,
              color: feature?.properties?.color,
              dashArray: '5',
              fillOpacity: 0.3,
            };
          } else {
            return {
              color: feature?.properties?.color,
              stroke: true,
              fillOpacity: 0.5,
            };
          }
        },
        pointToLayer: function (feature, latLng) {
          return L.circleMarker(latLng, {
            color: 'black',
            fillColor: feature?.properties?.color,
            fillOpacity: 1,
            weight: 1,
          }).bindTooltip(feature?.properties?.description);
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties.popupContent) {
            layer.bindPopup(
              `<div style="max-height: 500px;overflow: auto">${feature.properties.popupContent}</div>`
            );
          }
        },
      };

      props.geojson?.map((geo, index) => {
        const categories = uniqBy(geo.places, 'category_id');

        categories?.map((c: any) => {
          const currentCategoryId = c?.category_id;

          const subGroup = (L as any).featureGroup.subGroup(markers);

          const geoJsonFilteredArr = geo?.places?.filter(
            (gj) => gj.category_id === currentCategoryId
          );
          const geoJsonArr = geoJsonFilteredArr?.map((gg) => gg.geojson);

          const count = Object.values(
            countBy(geoJsonFilteredArr, (o) => o.category_id)
          )[0];

          control.current.addOverlay(
            subGroup,
            `<strong style="color: ${c?.category?.color};">${c?.category?.description} (${count})</strong>`
          );

          subGroup.addLayer(L.geoJSON(geoJsonArr, geojsonOptions));
          subGroup.addTo(map.current);
        });
      });

      const regionPolygon = L.geoJSON([props.geojson?.[0]?.geojson], {
        style: function (feature) {
          return {
            fillOpacity: 0.01,
            weight: 2,
            color: 'white',
          };
        },
      });

      regionPolygon.addTo(map.current);
      control.current.addTo(map.current);
      markers.addTo(map.current);
    }
  };

  useEffectOnce(() => {
    L.PM.setOptIn(true);

    map.current = L.map('map', { maxZoom: 18, pmIgnore: false });

    map.current.setView([-15.793889, -47.882778], 5);

    L.tileLayer(
      'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'
    ).addTo(map.current);

    L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png',
      { maxNativeZoom: 14, maxZoom: 15 }
    ).addTo(map.current);

    map.current.pm.addControls({
      position: 'topleft',
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawPolygon: false,
      drawCircle: false,
      drawText: false,
      cutPolygon: false,
      rotateMode: false,
      editMode: false,
    });

    map.current.on('pm:create', (e) => {
      e.layer.options.pmIgnore = false;
      L.PM.reInitLayer(e.layer);
    });

    map.current.on('pm:globalremovalmodetoggled', ({ enabled }) => {
      if (enabled) {
        setShowButton(false);

        return undefined;
      }

      setShowButton(true);

      const markers = handleGetLatLngFromMarkers(
        L.PM.Utils.findLayers(map.current)
      );

      setPointsToGoogle(markers);
    });

    map.current.on('pm:globaldrawmodetoggled', ({ enabled }) => {
      if (enabled) {
        setShowButton(false);

        return undefined;
      }

      setShowButton(true);

      const markers = handleGetLatLngFromMarkers(
        L.PM.Utils.findLayers(map.current)
      );

      setPointsToGoogle(markers);
    });

    map.current.on('pm:globaldragmodetoggled', ({ enabled }) => {
      if (enabled) {
        setShowButton(false);

        return undefined;
      }

      setShowButton(true);

      const markers = handleGetLatLngFromMarkers(
        L.PM.Utils.findLayers(map.current)
      );

      setPointsToGoogle(markers);
    });

    map.current.pm.setLang('pt_br');
  });

  React.useEffect(() => {
    if (props.center) {
      map.current.setView(props.center, 10);

      setTimeout(() => {
        handleChangeBounds();
      }, 300);
    }
  }, [props.center]);

  React.useEffect(() => {
    handleToMap();
  }, [props.geojson]);

  const handleChangeBounds = () => {
    const bounds = map.current.getBounds();

    dispatchRedux.updateFilters({
      northeast_lat: bounds?._northEast?.lat,
      northeast_lng: bounds?._northEast?.lng,
      southweast_lat: bounds?._southWest?.lat,
      southweast_lng: bounds?._southWest?.lng,
    });

    dispatchRedux.serviceGet();
  };

  const handleRedirectToGoogleMaps = () => {
    const points = pointsToGoogle?.reduce((acc: any, curr: any) => {
      return [...acc, [curr.lat, curr.lng].join(',')];
    }, []);

    const url = `https://www.google.com/maps/dir/${points.join('/')}`;

    window.open(url);
  };

  return (
    <Wrapper {...(full ? propsFull : undefined)}>
      {props?.loading && (
        <Box
          bgcolor="background.default"
          position="absolute"
          top={0}
          right={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1000}
        >
          <CircularProgress size="5rem" />
        </Box>
      )}
      <Box id="map" style={{ height: '100%' }} />

      {pointsToGoogle.length > 1 && showButton && (
        <Box
          position="absolute"
          bottom={50}
          left={0}
          display="flex"
          flexDirection={'column'}
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          width="100%"
        >
          <Button
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleRedirectToGoogleMaps}
          >
            Calcular rota
          </Button>
        </Box>
      )}
    </Wrapper>
  );
}
