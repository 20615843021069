import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset?: string;
  limit?: string;
  category?: string;
};

export default async function getApiMapCategoryService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['map/category', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'MAP_SPOTPROSPECT',
    auth: true,
  });
}
