import reducer from './dashboard.reducer';
import * as selector from './dashboard.selector';
import * as constant from './dashboard.constant';
import * as action from './dashboard.action';
import subscriber from './dashboard.subscriber';
import saga from './dashboard.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
  action,
};
