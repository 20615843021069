import { useSelector, useDispatch } from 'react-redux';
import { ptBR } from '@mui/material/locale';
import {
  createTheme,
  ThemeProvider as ThemeProviderMUI,
  CssBaseline,
} from '@mui/material';

import themeConstants from '@spot/shared-constants/theme.constant';

import misc from '@spot-spotprospect/store/configs/misc';

const ThemeProvider = ({ children }) => {
  const theme = createTheme(themeConstants('light') as any, ptBR);

  return (
    <ThemeProviderMUI theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProviderMUI>
  );
};

export default ThemeProvider;
