import { all } from 'redux-saga/effects';

import dialogs from './dialogs';
import authenticate from './authenticate';

function* watch() {
  yield all([dialogs.saga.watch(), authenticate.saga.watch()]);
}

export default {
  watch,
};
