import reducer from './configs.reducer';
import saga from './configs.saga';
import * as selector from './configs.selector';
import * as constant from './configs.constant';
import subscriber from './configs.subscriber';

export default {
  reducer,
  saga,
  constant,
  selector,
  subscriber,
  name: constant.NAME,
};
