import reducer from './category.reducer';
import * as constant from './category.constant';
import * as action from './category.action';
import * as selector from './category.selector';
import saga from './category.saga';
import initialState from './category.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
