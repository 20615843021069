import reducer from './region.reducer';
import * as constant from './region.constant';
import * as action from './region.action';
import * as selector from './region.selector';
import saga from './region.saga';
import initialState from './region.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
