import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  email: string;
  password: string;
};

export default async function postApiAuthService(payload: Payload) {
  const url = 'auth';

  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT_SPOTPROSPECT',
    body: JSON.stringify(payload),
  });
}
