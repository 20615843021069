import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';
import { TextField } from '@mui/material';

const FieldDocumentComponent = ({ name, ...restProps }) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
    meta: { error, touched },
  }: any) => {
    const lenghtValue = String(field.value)?.length;

    return (
      <>
        <NumberFormat
          customInput={TextField}
          format={lenghtValue < 12 ? '###.###.###-###' : '##.###.###/####-##'}
          fullWidth
          label="CNPJ ou CPF"
          variant="outlined"
          value={field.value}
          type="tel"
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);
          }}
          onValueChange={(values: any) =>
            setFieldValue(field.name, values.value)
          }
          {...restProps}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldDocumentComponent;
