import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';

import ROUTES from '@spot-spotprospect/constants/routes.constant';

import useQuery from '@spot/shared-hooks/useQuery.hook';
import useEffectOnce from '@spot-spotprospect/hooks/useEffectOnce.hook';

import auth from '@spot/shared-store/auth';

const AuthScreen = () => {
  const history = useHistory();

  const query = useQuery();

  const dispatch = useDispatch();

  const dispatchRedux = {
    setAuth: flow(auth.action.setToken, dispatch),
  };

  const token = query.get('token');
  const redirectUrl = query.get('redirectUrl');

  useEffectOnce(() => {
    dispatchRedux.setAuth(
      token,
      process.env.REACT_APP_COOKIE_KEY_TOKEN,
      process.env.REACT_APP_COOKIE_DOMAIN
    );

    window.location.href = redirectUrl || ROUTES.DASHBOARD.ROOT;
  });

  return <div>Carregando...</div>;
};

export default AuthScreen;
