import { combineReducers } from 'redux';

import drawers from './drawers';
import misc from './misc';

const reducer = combineReducers({
  [misc.name]: misc.reducer,
  [drawers.name]: drawers.reducer,
});

export default reducer;
