import decode from 'jwt-decode';
import { createReducer } from '@reduxjs/toolkit';

import initialState from './auth.initialState';
import { ACTION_TYPES } from './auth.constant';

const handleSetToken = (state, action) => {
  state.signedIn = true;
  state.token = action.token;
  state.user = decode(action.token);
};

const handleUnsetToken = (state) => {
  state.signedIn = false;
  state.token = null;
  state.user = null;
};

const handleResetState = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.TOKEN.SET]: handleSetToken,
  [ACTION_TYPES.TOKEN.UNSET]: handleUnsetToken,
  [ACTION_TYPES.RESET.STATE]: handleResetState,
});
