import { takeEvery, call } from 'redux-saga/effects';

import getHistory from '@spot/shared-utils/getHistory.util';

import { ACTION_TYPES } from './router.constant';

const history = getHistory();

function* handlePush(action) {
  yield call(history.push, action.payload.href);
}

function* handleReplace(action) {
  yield call(history.replace, action.payload.href);
}

function* handleGo(action) {
  yield call(history.go, action.payload.index);
}

function* handleBack() {
  yield call(history.goBack);
}

function* handleForward() {
  yield call(history.goForward);
}

function* watch() {
  yield takeEvery(ACTION_TYPES.PUSH, handlePush);
  yield takeEvery(ACTION_TYPES.REPLACE, handleReplace);
  yield takeEvery(ACTION_TYPES.GO, handleGo);
  yield takeEvery(ACTION_TYPES.BACK, handleBack);
  yield takeEvery(ACTION_TYPES.FORWARD, handleForward);
}

export default {
  watch,
};
