import reducer from './map.reducer';
import * as constant from './map.constant';
import * as action from './map.action';
import * as selector from './map.selector';
import saga from './map.saga';
import initialState from './map.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
